import { styled } from "../../core/core";

    styled.css `
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        align-content: start;
        overflow-y: hidden;
        overflow-x: auto;
        scrollbar-width: none;

        scroll-snap-type: x mandatory;
        scroll-snap-stop: always;

        & > * {
            scroll-snap-align: center;
            scroll-snap-stop: always;
        }

        & > *:first-child {
            scroll-snap-align: start;
        }

        & > *:last-child {
            scroll-snap-align: end;
        }
    `.installGlobal("horizontal-list");

export default class HorizontalList extends HTMLElement {}

customElements.define("horizontal-list", HorizontalList);
