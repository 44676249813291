export const installCss = (prefix, remainingUrl) => {
    for(const link of Array.from(document.querySelectorAll(`link[rel=stylesheet]`))) {
        const href = link.getAttribute("href");
        if(href.startsWith(prefix)) {
            return;
        }
    }
    const css = document.createElement("link");
    css.rel = "stylesheet";
    css.href = prefix + remainingUrl;
    document.head.appendChild(css);
};