import { styled } from "../../core/core";
import HtmlControl from "../../core/HtmlControl";
import { XNode } from "../../core/XNode";
import "../popup/PopupWindow";

    styled.css `

        display: grid;
        grid-template-rows: auto 1fr auto;

        gap: 5px;

        max-height: 90vh;
        max-width: 90vw;

        @media (min-width:701px) {
            & {
                max-width: 700px;
            }
        }

        & > header {
            grid-row: 1;
        }

        & > section {

            grid-row: 2;

            display: flex;
            flex-wrap: wrap;

            column-gap: 20px;
            row-gap: 10px;

            align-content: start;
            justify-content: start;

            overflow: auto;

            & > a {
                color: inherit;
                margin: 5px;
                text-decoration: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;

                &[data-hide] {
                    display: none;
                }

                & > i.fab {
                    &.fa-email,&.fa-gmail,&.fa-hacker-news,&.fa-mail-ru{
                        font: var(--fa-font-solid);
                        &::before {
                            content: "@";
                        }
                    }
                    &.fa-sms{
                        font: var(--fa-font-solid);
                        &::before {
                            content: "\uf7cd";
                        }
                    }
                    &.fa-social-mail{
                        font: var(--fa-font-solid);
                        display: grid;
                        align-items: center;
                        justify-content: center;
                        justify-items: center;
                        position: relative;
                        &::before {
                            grid-column: 1;
                            grid-row: 1;
                            content: "\uf27a";
                        }
                        &::after {
                            grid-column: 1;
                            grid-row: 1;
                            position: absolute;
                            top: 2px;
                            color: white;
                            font-size: x-small;
                            content: "\u0040";
                        }
                    }
                }
            }
        }

        & > footer {
            grid-row: 3;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            & > button {
                padding: 10px;
                border-color: transparent;
                border-radius: 20px;
                padding-left: 20px;
                padding-right: 20px;
                background-color: lightgreen;
                cursor: pointer;
            }
        }

    `.installGlobal("share-window");

    const buildUrl = (url: string , p ) => {
        let start = url;
        if (!/\#|\?/.test(start)) {
            start += "?";
        }
        for (const key in p) {
            if (Object.prototype.hasOwnProperty.call(p, key)) {
                const element = p[key];
                start += `${encodeURIComponent(key)}=${encodeURIComponent(element)}&`
            }
        }
        return start;
    }


export class ShareWindow extends HtmlControl {

    prepare() {

        const isMailTo = this.hasAttribute("mailto");

        if (isMailTo) {
            return this.prepareMailTo();
        }

        const url = this.getAttribute("url");
        const imageUrl = this.getAttribute("image-url");
        const title = this.getAttribute("title") || "Share";

        const share = !!navigator.share;

        XNode.render(this, <div>
            <header>
                <input type="search"/>
            </header>
            <section>
                {shareLink("Social Mail", "https://share.socialmail.me", { mailto: url })}
                {shareLink("Facebook", "https://www.facebook.com/sharer/sharer.php", { u: url, t: title })}
                {shareLink("Twitter", "https://twitter.com/share", { url, text: title })}
                {/* {shareLink("AOL", "https://www.addtoany.com/add_to/aol_mail", { linkurl: url, linkname: title })} */}
                {shareLink("Blogger", "https://www.blogger.com/blog_this.pyra", { u: url, n: title })}
                {shareLink("Email", "mailto:", { subject: title, body: `<a href="${url}">${title}</a>` })}
                {shareLink("LinkedIn", "https://www.linkedin.com/feed/", { shareActive: true, text: `${url}` })}
                {shareLink("Mastodon", "https://mastodonshare.com/", { url, title })}
                {shareToSms(url)}
                {shareLink("WhatsApp", "https://api.whatsapp.com/send", { text: url })}
                {shareLink("Yahoo", "http://compose.mail.yahoo.com/", { subject: title, body: url })}
                {shareLink("GMail", "https://mail.google.com/mail/u/0/", { ui:2, fs:1, tf:"cm", su: title, body: url } )}
                {shareLink("Hacker News", "https://news.ycombinator.com/submitlink", { u: url, t: title })}
                {shareLink("Mail.ru", "https://connect.mail.ru/share", { share_url: url, t: title })}
                {shareLink("Pinterest", "http://pinterest.com/pin/create/link/", { url, description: title + "\n" + url} )}
                {shareLink("Reddit", "https://www.reddit.com/submit", { url, title })}
                {shareLink("Snapchat", "https://www.snapchat.com/scan", { attachmentUrl: url })}
                {shareLink("Telegram", "https://telegram.me/share/url", { url, text: title })}
                
            </section>
            { share && <footer>
                <button event-click={() => navigator.share({ url, title }).catch(console.error) }>Share</button>
                <sup>Share using browser's default share settings.</sup>
            </footer> }
        </div>);

        this.addEventListener("input", (x: InputEvent) => {
            const target = x.target as HTMLInputElement;

            const all = this.querySelectorAll("a[data-name]");

            const v = target.value.trim().toLocaleLowerCase();
            if (v) {
                all.forEach((e) => e.getAttribute("data-name")?.toLowerCase()?.includes(v) ? e.removeAttribute("data-hide") : e.setAttribute("data-hide", "hide") );
            } else {
                all.forEach((e) => e.removeAttribute("data-hide"));
            }
        })
    }

    prepareMailTo() {
        const url = this.getAttribute("url");
        const share = !!navigator.share;

        const u = new URL(url, location.href);

        const to = u.hostname || u.pathname;
        const subject = u.searchParams.get("subject");
        const body = u.searchParams.get("body") || "";
        const inReplyTo = u.searchParams.get("in-reply-to");

        XNode.render(this, <div>
            <header>
                <input type="search"/>
            </header>
            <section>
                {shareLink("Social Mail", "https://share.socialmail.me", { mailto: url })}
                {shareLink("Email", "mailto:" + to, { subject, body, ["in-reply-to"]: inReplyTo })}
                {shareLink("Yahoo", "http://compose.mail.yahoo.com/", { to, subject, body, ["in-reply-to"]: inReplyTo })}
                {shareLink("GMail", "https://mail.google.com/mail/u/0/", { ui:2, fs:1, tf:"cm", su: subject, body, to, ["in-reply-to"]: inReplyTo } )}
                {shareLink("Mail.ru", "https://connect.mail.ru/share", { share_url: url, to, t: subject, ["in-reply-to"]: inReplyTo })}
            </section>
            { share && <footer>
                <button event-click={() => navigator.share({ url, title: "Mail" }).catch(console.error) }>Share</button>
                <sup>Share using browser's default share settings.</sup>
            </footer> }
        </div>);

    }

}

customElements.define("share-window", ShareWindow);

const shareLink = (name: string, url: string, q: any, image?) =>
    <a
        data-name={name}
        title={`Share on ${name}`}
        target="_blank"
        href={ buildUrl(url, q)}>
            { image ? <img src={image}/> : <i class={`fab fa-${name.toLocaleLowerCase().replace(/[\s\.]+/g, "-")}`}/> }
            {name}
        </a>; 

const shareToSms = (url: string) => shareLink("SMS",  /android/i.test(navigator.userAgent)
    ? `SMS:?body=${encodeURIComponent(url)}`
    :`SMS:?&body=${encodeURIComponent(url)}`, {});