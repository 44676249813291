import { ComponentHelper } from "../../core/ComponentHelper";
import { asCssDataUrl, styled } from "../../core/core";
import HtmlControl from "../../core/HtmlControl";

const svgBars = asCssDataUrl(`<svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>`);

const svgCross = asCssDataUrl(`<svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path class="fa-secondary" opacity=".4" d="M297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6z"/><path class="fa-primary" d=""/></svg>`);

    styled.css `
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;
        gap: 5vw;
        white-space: nowrap;

        & > input:first-of-type {
            display: none;
        }

        & > input:first-of-type ~ * {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: auto;
            gap: var(--menu-bar-item-gap, 5vw);
        }

        @media (min-width: 701px) {
            & {
                flex: 1 1 80%;
            }
            & > nav {
                align-content: end ;
                justify-content: space-between;

                & > * {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    position: relative;
                    cursor: pointer;
                    &::before {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 2px;
                        bottom: 5px;
                        left: 0;
                        background-color: currentColor;
                        transform: scaleX(0);
                        transition: transform 0.3s ease;
                    }

                    &:hover::before {
                        transform: scaleX(1);
                    }
                }

            }
        }


        @media (max-width: 700px) {

            & {
                margin-left: auto;
                flex-direction: column;
                place-self: center;
                position: relative;
            }

            & > input:first-of-type {
                align-self: center;
                justify-self: right;
                display: inline-block;
                background-color: transparent;
                background-image: ${svgBars};
                background-position: 2px 2px;
                border-radius: 2px;
                border-width: 1px;
                border-style: solid;
                border-color: transparent;
                background-repeat: no-repeat;
                width: 21px;
                height: 22px;
                appearance: none;
                outline: none;
            }
            & > input:first-of-type:checked {
                background-image: ${svgCross};
            }

            & > input:first-of-type:not(:checked) ~ * {
                display: none;
            }

            & > input:first-of-type ~ * {
                position: absolute;
                right: 10px;
                top: 3ch;
                display: flex;
                gap: 0;
                flex-direction: column;
                background-color: var(--menu-bg-color, canvas);
                padding: 10px;
                border-radius: 10px;
                justify-content: start;
                align-content: start;
                align-items: start;
                max-width: calc(100vh - 30px);
                box-shadow: 0 6px 9px 6px rgba(0,0,0,.25);
                & > * {
                    padding-left: 20px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
        }

    `.installGlobal("menu-bar");

export default class MenuBar extends HtmlControl {

    async prepare() {

        if (this.isContentEditable) {
            return;
        }

        await ComponentHelper.waitForReady();

        const nav = document.createElement("nav");
        for(const child of Array.from(this.children)) {
            child.remove();
            nav.appendChild(child);
        }

        const checkbox = document.createElement("input");
        checkbox.type = "checkbox";
        this.appendChild(checkbox);
        this.appendChild(nav);


    }
}

customElements.define("menu-bar", MenuBar);
