(Symbol as any).dispose ??= Symbol("dispose");
(Symbol as any).asyncDispose ??= Symbol("asyncDispose");

export default class DisposableList implements Disposable {

    private list: Disposable[] = [];

    add(d: Disposable | DisposableList) {
        if (d instanceof DisposableList) {
            for (const element of d.list) {
                this.list.push(element);
            }
            d.list.length = 0;
            return;
        }
        this.list.push(d);
    }

    [Symbol.dispose]() {
        let lastError = null;
        for (const item of this.list) {
            try {
                item[Symbol.dispose]();
            } catch (error) {
                lastError = error;
            }
        }
        if (lastError) {
            throw lastError;
        }
    }
}
