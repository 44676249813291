
export default class ScrollHelper {

    static bringIntoView(element: HTMLElement, useNative = true) {
        // if (useNative) {
        //     element.scrollIntoView({
        //         behavior: "smooth",
        //         block: "nearest",
        //         inline: "center"
        //     });
        //     return;
        // }

        let parent = (element.assignedSlot ?? element).parentElement;
        while(parent && parent.scrollWidth === parent.clientWidth) {
            element = parent;
            parent = parent.parentElement;
        }
        if (!parent) {
            return;
        }

        const tol = element.offsetLeft;
        const pow = parent.offsetWidth;
        const tow = element.offsetWidth;

        const left = tol - (pow - tow)/2;
        parent.scrollTo({
            left,
            top: 0,
            behavior: "smooth"
        });
    }

}