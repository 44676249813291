import { ComponentHelper } from "../../core/ComponentHelper";
import { styled } from "../../core/core";

    styled.css `
        display: inline-block;
        position: relative;
        & div {
            margin: 10px;
        }
    `.installGlobal("pdf-page");


let nextId = 1;

export class PdfPage extends HTMLElement {

    static observedAttributes = ["scale", "hide"];

    pdfDoc: any;
    index: number;
    canvas: HTMLCanvasElement;
    lastUpdate: any;
    updatePromise: any;
    renderText: boolean;
    TextLayer: any;

    connectedCallback() {
        this.prepare().catch(console.error);
    }

    async prepare() {

        await ComponentHelper.waitForReady();
        this.updateScale().catch(console.error);
    }

    attributeChangedCallback(name, oldValue, newValue) {
        switch(name) {
            case "scale":
                this.updateScale().catch(console.error);
                break;
            case "hide":
                this.queueUpdate();
                break;
        }
    }

    async updateScale() {
        const page = await this.pdfDoc.getPage(this.index);
        const scale = Number(this.getAttribute("scale") || "0.5");
        const viewport = page.getViewport({ scale });
        this.style.minWidth = (viewport.width + 20) + "px";
        this.style.minHeight = (viewport.height + 20) + "px";
        this.queueUpdate();
    }

    queueUpdate() {
        const { lastUpdate } = this;
        if (lastUpdate) {
            clearTimeout(lastUpdate);
        }
        this.lastUpdate = setTimeout(() =>
            (this.updatePromise ??= this.updateCanvas()).then(() => this.updatePromise = null, console.error)
        , 100);
    }

    async updateCanvas() {
        if (/true|yes/i.test(this.getAttribute("hide"))) {
            this.innerHTML = "";
            this.canvas = null;
            this.updatePromise = null;
            return;
        }

        let canvas = this.canvas;
        if (!canvas) {
            canvas = document.createElement("canvas");
            this.appendChild(canvas);
            this.canvas = canvas;
        }



        const page = await this.pdfDoc.getPage(this.index);
        const scale = Number(this.getAttribute("scale") || "0.5");
        const viewport = page.getViewport({ scale });
        const canvasContext = canvas.getContext("2d");

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        await page.render({
            canvasContext,
            viewport
        }).promise;

        if (!this.TextLayer) {
            this.updatePromise = null;
            return;
        }

        const textLayerDiv = document.createElement("div");
        textLayerDiv.className = "textLayer";
        this.appendChild(textLayerDiv);
        textLayerDiv.style.setProperty("--scale-factor", scale.toFixed(2));
        const textContentSource = await page.streamTextContent();        

        const textLayer = new this.TextLayer({
            textContentSource,
            container: textLayerDiv,
            viewport
        });

        textLayer.render();


        // await page.renderTextLayer({
        //     textContent,
        //     container,
        //     viewport,
        //     textDivs: []
        // })

        this.updatePromise = null;
    }
}

customElements.define("pdf-page", PdfPage);