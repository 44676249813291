import { ComponentHelper } from "../../core/ComponentHelper";
import { styled } from "../../core/core";
import { installCss } from "../../core/installCss";
import { XNode } from "../../core/XNode";
import { PdfPage } from "./PdfPage";

    styled.css`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns:auto 1fr auto;
    grid-template-rows: auto 1fr auto auto;
    overflow: hidden;
    background: gray;

    & > .title {
        position: absolute;
        left: 5px;
        top: 5px;
    }

    & > .preview {
        display: flex;
        grid-row: 2;
        grid-column: 1;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        gap: 10px;
        & > * {
            cursor: pointer;
            padding: 10px;
            max-height: 30vh;
            max-width: 20vw;
            &:hover {
                background: highlight;
            }
        }
    }

    & > .viewer {
        grid-row: 2;
        grid-column: 2;
        overflow: auto;
        gap: 10px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        & > * {
            padding: 10px;
        }
    }

    & > .toolbar {
        grid-column: 1 / span 3;
        grid-row: 1;
        display: flex;
        flex-direction: row;
        gap: 10px;
        background-color: lightgray;

        & > * {
            flex: 1 1 33%;
        }

        & > .left {
            margin-right: auto;
        }

        & > .middle {
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            justify-content: center;
            & > i {
                padding: 5px;
                cursor: pointer;
            }
            & > input {
                max-width: 50px;
            }
        }

        & > .right {
            display: flex;
            margin-left: auto;
            align-items: center;
            justify-content: end;
            gap: 5px;
            & > i {
                cursor: pointer;
                padding: 5px;
            }
        }
    }

    &[align-left=true] {
        & > .viewer {
            align-items: start;
        }
    }

    @media (max-width: 500px) {
        & > .preview {
            grid-row: 3;
            grid-column: 2 / span 2;
            max-height: 30vh;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
        }
    }

    `.installGlobal("pdf-frame");

    styled.css `
        overflow: hidden;
    `.installGlobal("body[stop-overflow]")


export class PdfFrame extends HTMLElement {

    pdfDoc;
    showClose: boolean;
    observer: IntersectionObserver;
    observed: Set<any>;    
    viewer: HTMLElement;
    scaleElement: HTMLElement;
    TextLayer: any;

    connectedCallback() {
        this.prepare().catch(console.error);
    }

    disconnectedCallback() {
        const { observer } = this;
        for (const iterator of this.observed) {
            observer.unobserve(iterator);
        }
        document.body.removeAttribute("stop-overflow");
    }
    async prepare() {

        await ComponentHelper.waitForReady();

        document.body.setAttribute("stop-overflow", "true");

        installCss("https://cdn.jsdelivr.net/npm/pdfjs-dist", "@4.4.168/web/pdf_viewer.css");

        this.observer = new IntersectionObserver((entries) => {
            
            for (const iterator of entries) {
                if(iterator.isIntersecting) {
                    iterator.target.setAttribute("hide", "false");
                } else {
                    iterator.target.setAttribute("hide", "true");
                }
            }
            
        }, { root: this });
        this.observed = new Set();
        const src = this.getAttribute("src");
        const allowDownload = this.hasAttribute("allow-download");

        (<div>
            <div class="preview"/>
            <div class="viewer"/>
            <div class="toolbar">
                <div class="left"></div>
                <div class="middle">
                    <i class="fas fa-minus" event-click={() => this.changeScale(-0.1)}/>
                    <div class="scale" type="number" />
                    <i class="fas fa-plus"  event-click={() => this.changeScale(0.1)}/>
                </div>
                <div class="right">
                    { allowDownload && <a
                        href={src}

                        class="fas fa-download"></a>}
                    <i
                        title="Close"
                        event-click={() => this.remove()} class="fas fa-times-circle" style="color:red"/>
                </div>
            </div>
        </div>).render(this);

        const viewer = this.viewer = this.querySelector(".viewer");
        const preview = this.querySelector(".preview");
        this.scaleElement = this.querySelector(".scale");

        const scale = Number(this.getAttribute("scale") || "1");

        const { pdfDoc } = this;

        this.scaleElement.textContent = (scale * 100) + "%";
        this.updateScale();

        for(let i=1; i<=pdfDoc.numPages;i++) {

            const fullView = document.createElement("pdf-page") as PdfPage;
            fullView.pdfDoc = pdfDoc;
            fullView.index = i;
            fullView.TextLayer =  this.TextLayer;
            fullView.setAttribute("scale", scale.toString());
            viewer.appendChild(fullView);

            this.observed.add(fullView);
            this.observer.observe(fullView);

            const thumbnail = document.createElement("pdf-page") as PdfPage;
            thumbnail.pdfDoc = pdfDoc;
            thumbnail.index = i;
            (thumbnail as any).fullView = fullView;
            thumbnail.setAttribute("scale", "0.3");
            thumbnail.setAttribute("preview", "true");
            preview.appendChild(thumbnail);            

            this.observed.add(thumbnail);
            this.observer.observe(thumbnail);
        }

        this.addEventListener("click", (e) => {
            let start = e.target as HTMLElement;
            while (start) {
                const fullView = (start as any).fullView as HTMLElement;
                if (fullView) {
                    fullView.scrollIntoView({ behavior: "smooth", block: "nearest" });
                    return;
                }
                start = start.parentElement;
            }
        });

    }
    updateScale() {
        const alignLeft = this.viewer.scrollWidth > this.viewer.offsetWidth ? "true" : "false";
        this.setAttribute("align-left", alignLeft);
    }

    changeScale(a: number) {
        let scale = Number(this.getAttribute("scale") || "1") + a;
        const scaleText = scale.toFixed(2);
        this.setAttribute("scale", scaleText);
        this.scaleElement.textContent = Math.floor(scale * 100) + "%";

        let start = this.viewer.firstElementChild;
        while(start) {
            start.setAttribute("scale", scaleText);
            start = start.nextElementSibling;
        }
        this.updateScale();
    }

}

customElements.define("pdf-frame", PdfFrame);
