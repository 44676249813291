import { IEditableAttribute, IEditableElement } from "./EditableAttributes";

export default abstract class HtmlControl extends HTMLElement implements IEditableElement {

    get editableAttributes(): IEditableAttribute[] {
        return [];
    }

    connectedCallback() {

        this.onConnected().catch(console.error);
    }

    onConnected() {

        const ready = (async () => {

            await this.prepare();

        })();

        Object.defineProperty(this, "onConnected", { value: () => ready})
        return ready;
    }

    abstract prepare(): any;

}